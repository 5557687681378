<template>
  <div class="followAccount">
    <!-- <div class="refreshListBtnDiv">
      
        <el-button class="confirmDialogBtn" @click="getData(currentPage,pageSize)" icon="el-icon-refresh-right">
            {{
                $t("ManualReplenishment_refresh")
            }}</el-button>
    </div> -->
    <div class="bscroll" ref="bscroll">
          <div class="bscroll-container">
    <div class="mobileFilterDiv" v-if="mobileFlag">
              <div>
                <el-button class="mobileFilterBtn" @click="addFollow(true)">
                  {{ $t("MyFollowers_InvitetoCopy") }}
                  <i class="el-icon-plus el-icon--right"></i>
                </el-button>
                <!-- <el-button class="mobileFilterBtn" @click="relieveMore()">
                  {{ $t("relieveAll") }}
                  <svg-icon style="margin-left:5px" class="greenIcon" icon-class="RelieveIcon"></svg-icon>
                </el-button> -->
              </div>
              <el-button class="mobileFilterBtn"  @click="mobileSearchDrawer = true"  type="text" size="small">
                {{ $t("mobileFilterBtnTxt") }}
                <svg-icon class="greenIcon" icon-class="MobileFilterBtn"></svg-icon>
              </el-button>
      </div>
      
    <div class="tableSearch" v-if="!mobileFlag">
      <el-form label-position="top" :model="tableSearchForm" ref="tableSearchForm">
        <el-form-item :label="$t('MyFollowers_Mt4AccountSearch')" prop="Account">
          <el-input v-model="tableSearchForm.Account"></el-input>
        </el-form-item>
        <el-form-item :label="$t('MyFollowers_CustomerAccountSearch')" prop="LoginId">
          <el-input v-model="tableSearchForm.LoginId"></el-input>
        </el-form-item>
        <el-form-item :label="$t('MyFollowers_WhetherToAutomaticallyReplenishOrders')" prop="AutoRecon">
          <el-select v-model="tableSearchForm.AutoRecon">
            <el-option :label="$t('MyFollowers_Yes')" value="true"></el-option>
            <el-option :label="$t('MyFollowers_No')" value="false"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item :label="$t('MyFollowers_Activation1')" prop="Activation">
                    <el-select v-model="tableSearchForm.Activation">
                        <el-option :label="$t('MyFollowers_Yes')" value="true"></el-option>
                        <el-option :label="$t('MyFollowers_No')" value="false"></el-option>
                    </el-select>
                </el-form-item> -->
        <el-form-item :label="$t('MyFollowers_Status')" prop="FollowStatus">
          <el-select v-model="tableSearchForm.FollowStatus">
            <el-option :label="$t('MyFollowers_AllStatus')" value=""></el-option>
            <el-option :label="$t('MyFollowers_InvitedPendingApproval')" value="0"></el-option>
            <el-option :label="$t('MyFollowers_InvitationAccepted')" value="4"></el-option>
            <el-option :label="$t('MyFollowers_InvitationDeclined')" value="2"></el-option>
            <el-option :label="$t('MyFollowers_Copying')" value="1"></el-option>
            <!-- <el-option :label="$t('MyFollowers_StopCopying1')" value="3"></el-option> -->
            <!-- <el-option label="停止跟单" value="3"></el-option> -->
          </el-select>
        </el-form-item>
        <!-- <el-form-item v-if="mobileFlag"></el-form-item> -->
        <el-form-item class="noLabel">
          <el-button class="serchFormBtn" @click="getData(1, pageSize,'search')">
            {{ $t("MyFollowers_Search") }}
            <svg-icon icon-class="SearchIcon"></svg-icon>
          </el-button>
        </el-form-item>
        <el-form-item class="noLabel">
          <el-button class="resetFormBtn" @click="resetForm()">
            {{ $t("clearAll") }}
            <svg-icon icon-class="ResetIcon"></svg-icon>
          </el-button>
        </el-form-item>
        <el-form-item class="noLabel" >
          <el-button class="serchFormBtn" @click="addFollow(true)">
            {{ $t("MyFollowers_InvitetoCopy") }}
            <i class="el-icon-plus el-icon--right"></i>
          </el-button>
        </el-form-item>
        <!-- <el-form-item class="noLabel" >
          <el-button class="resetFormBtn" @click="relieveMore()">
            {{ $t("relieveAll") }}
            <svg-icon style="margin-left:5px" class="greenIcon" icon-class="RelieveIcon"></svg-icon>
          </el-button>
        </el-form-item> -->
      </el-form>
      <!-- <el-button v-if="!mobileFlag" @click="addFollow(true)" class="pageTitleBtn">{{ $t("MyFollowers_InvitetoCopy") }}
        <i class="el-icon-plus el-icon--right"></i></el-button>
      <el-button v-if="!mobileFlag" @click="relieveMore()" class="resetFormBtn phoneBtnleft">{{ $t("relieveAll") }}
        <svg-icon style="margin-left:5px" class="greenIcon" icon-class="RelieveIcon"></svg-icon>
      </el-button> -->
    </div>
    <div class="tableDiv" v-if="!mobileFlag">
      <el-table :data="tableData" border style="width: 100%" @selection-change="handleSelectionChange" ref="dataTable"  :summary-method="getSummaries" show-summary>
        <el-table-column :fixed="tableData.length !== 0?true:false" type="selection" width="50" :selectable="selectable" >
        </el-table-column>
        <el-table-column width="80"  v-if="!mobileFlag" prop="sortNum" :label="$t('MyFollowers_Number')">
        </el-table-column>
        <el-table-column  :width="flexLabelWidth($t('MyFollowers_CopyAccount'))" prop="followLoginId"
          :label="$t('MyFollowers_CopyAccount')">
        </el-table-column>
        <el-table-column :width="flexLabelWidth($t('MyFollowers_MT4Account'))" prop="followAccount"
          :label="$t('MyFollowers_MT4Account')">
          
        </el-table-column>
        <el-table-column prop="notSettlementProfit" :label="$t('ToBeDivided')">
          <template slot="header" slot-scope="scope">
              <div class="tableHeaderDiv label-box">
                <span>{{ $t("ToBeDivided") }} </span>
                <el-tooltip  class="item" effect="dark"
                   placement="top">
                  <div slot="content">{{$t('descTitle')}}<br/>{{$t('ToBeDividedDesc1')}}<br/>{{$t('ToBeDividedDesc2')}}</div>
                  <a href="javascript:;"
                    @click.stop="labelIconClick($t('ToBeDivided'), $t('ToBeDividedDesc'))">

                    <svg-icon class="labelIcon" icon-class="LabelIcon"></svg-icon>
                  </a>
                </el-tooltip>
              </div>
            </template>
            <template slot-scope="scope">
                {{scope.row.notSettlementProfit? formatNumberFractionDigits(scope.row.notSettlementProfit) : '0.00' }}
            </template>
        </el-table-column>
        <el-table-column prop="beginPeriodEquity" :label="$t('InitialNetValue')">
          <template slot-scope="scope">
            <div>
              {{ scope.row.beginPeriodEquity? formatNumberFractionDigits(scope.row.beginPeriodEquity)  : '0.00' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="currentPeriodDeposit" :label="$t('CurrentDeposit')">
          <template slot-scope="scope">
            <div>
              {{ scope.row.currentPeriodDeposit?formatNumberFractionDigits(scope.row.currentPeriodDeposit)  : '0.00' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="equity" :label="$t('NetCurrentValue')">
          <template slot-scope="scope">
            <div>
              {{ scope.row.equity?formatNumberFractionDigits(scope.row.equity)  : '0.00' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="balance" :label="$t('NetCurrentBalance')">
          <template slot-scope="scope">
            <div>
              {{ scope.row.balance?formatNumberFractionDigits(scope.row.balance) : '0.00' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="profit" :label="$t('NetCurrentProfit')">
          <template slot-scope="scope">
            <div :class="[scope.row.profit > 0 ?'greenNum':(scope.row.profit < 0?'redNum':'') ]">
              {{ scope.row.profit > 0 ? '+' + formatNumberFractionDigits(scope.row.profit) :(scope.row.profit < 0? formatNumberFractionDigits(scope.row.profit):'0.00') }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="totalCompleteProfit" :label="$t('BeenDivided')">
          <template slot-scope="scope">
            <div class="clickClass" @click="goHistoryProfit(scope.row.followAccount)">
              {{ scope.row.totalCompleteProfit?formatNumberFractionDigits(scope.row.totalCompleteProfit) : '0.00' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="totalProfit" :label="$t('tradeTotalProfit')">
          <template slot-scope="scope">
            <div :class="[scope.row.totalProfit > 0 ?'greenNum':(scope.row.totalProfit < 0?'redNum':'') ]">
              {{ scope.row.totalProfit > 0 ? '+' + formatNumberFractionDigits(scope.row.totalProfit) :(scope.row.totalProfit < 0? formatNumberFractionDigits(scope.row.totalProfit):'0.00') }}
            </div>
          </template>
        </el-table-column>
        
        <el-table-column :width="flexLabelWidth($t('MyFollowers_CopyRatio'))" :label="$t('MyFollowers_CopyRatio')">
          <template slot-scope="scope">
            <div>
              {{ scope.row.following + "%" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="maxVolume" width="100" :label="$t('MyFollowers_MaxLot1')">
          <template slot-scope="scope">
            <div>
              {{ scope.row.maxVolume == 0? $t('nolimit'):scope.row.maxVolume + 'lots' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="currentPeriodVolumes" :label="$t('CurrentTradingVolume')">
          <template slot-scope="scope">
            <div>
              {{ scope.row.currentPeriodVolumes + 'lots' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="currentSettlementBeginTime" width="150" :label="$t('startTimeOfThisPeriod')">
        </el-table-column>
        <el-table-column width="160" :label="$t('MyFollowers_AutomaticOrderReplenishment')">
          <template slot-scope="scope">
            <div>
              <el-result v-if="scope.row.autoRecon" icon="success"></el-result>
              <el-result v-if="!scope.row.autoRecon" icon="error"></el-result>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :width="mobileFlag?150:flexLabelWidth($t('loginStatus'))"
          prop="mtApiLoginStatus"
          :label="$t('loginStatus')"
        >
          <template slot-scope="scope">
            <div>
              <div class="loginStatus" v-if="scope.row.mtApiLoginStatus == 0">
                <el-result  icon="success" ></el-result>
                <span>{{$t('loginStatusOnline')}}</span>
                
              </div>
              <div class="loginStatus" v-if="scope.row.mtApiLoginStatus !== 0">
                <el-result  icon="error" ></el-result>
                <span>{{$t('loginStatusOutline')}}</span>
                
              </div>
              
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column :width="flexLabelWidth($t('MyFollowers_ActivationStatus'))"
          :label="$t('MyFollowers_ActivationStatus')">
          <template slot-scope="scope">
            <div>
              <el-result v-if="scope.row.activation" icon="success"></el-result>
              <el-result v-if="!scope.row.activation" icon="error"></el-result>
            </div>
          </template>
        </el-table-column> -->
        
        <!-- <el-table-column :width="flexLabelWidth($t('MyFollowers_mainCopyMaxDrawdown'))" prop="maxDrawdown"
          :label="$t('MyFollowers_mainCopyMaxDrawdown')">
        </el-table-column> -->
        <!-- <el-table-column :width="flexLabelWidth($t('MyFollowers_copyMaxDrawDown'))" prop="accountMaxDrawdown"
          :label="$t('MyFollowers_copyMaxDrawDown')">
        </el-table-column>
        <el-table-column :width="flexLabelWidth($t('MyFollowers_StopProfit'))" prop="takeProfit"
          :label="$t('MyFollowers_StopProfit')">
        </el-table-column> -->
        <el-table-column prop="symbols" width="135" 
          :label="$t('MyFollowers_Instruments1')">
          <template slot-scope="scope">
            <div>
              <span class="symbolsSpan" v-if="scope.row.forexSelect">
                {{ $t("MyFollowers_Fx") }}
              </span>
              <span class="symbolsSpan" v-if="scope.row.metalsSelect">
                {{ $t("MyFollowers_Metal") }}
              </span>
              <span class="symbolsSpan" v-if="scope.row.energySelect">
                {{ $t("MyFollowers_Energy") }}
              </span>
              <span class="symbolsSpan" v-if="scope.row.indicesSelect">
                {{ $t("MyFollowers_Indices") }}
              </span>
              <span class="symbolsSpan" v-if="scope.row.cryptoSelect">
                {{ $t("MyFollowers_Crypto") }}
              </span>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column :label="$t('MyFollowers_CopyDirection')">
                  <template slot-scope="scope">
                      <div>
                          {{ scope.row.followDirection ? $t('MyFollowers_ReverseCopy') : $t('MyFollowers_Copy')}}
                      </div>
                  </template>
              </el-table-column> -->
        
        <!-- <el-table-column prop="profit" :label="$t('echarts_pl')">
          <template slot-scope="scope">
                <div :class="scope.row.profit > 0?'greenNum':'redNum'">
                  {{ scope.row.profit ? scope.row.profit : 0 }}
                </div>
              </template>
        </el-table-column> -->
       
        <el-table-column width="140" :label="$t('MyFollowers_Status')" class="statusBr">
          <template slot-scope="scope">
            <div>
              
              <span v-if="scope.row.status == 0" class="statusGreen">{{
                $t("MyFollowers_InvitedPendingApproval")
              }}</span>
              <span v-if="scope.row.status == 4" class="statusYellow">{{
                $t("MyFollowers_InvitationAccepted")
              }}</span>
              <span v-if="scope.row.status == 2" class="statusRed">{{
                $t("MyFollowers_InvitationDeclined")
              }}</span>
              <span v-if="scope.row.status == 1" class="statusYellow">{{
                $t("MyFollowers_Copying")
              }}</span>
              <span v-if="scope.row.status == 3" class="statusRed">{{
                $t("MyFollowers_StopCopying1")
              }}</span>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column width="150" prop="followTime" :label="$t('MyFollowers_startFollowHistory')">
          <template slot-scope="scope">
              <div>
                    {{
                     scope.row.status == 1?scope.row.followTime:''
                    }}
                </div>
              </template>
        </el-table-column> -->
        <el-table-column :fixed="tableData.length !== 0?'right':false" v-if="!mobileFlag" width="135" :label="$t('MyFollowers_Operation')">
          <template slot-scope="scope">
            <!-- {{ scope.row.insTime | dateFilter }} -->
            <div class="operateBtns">
              <!-- <el-button @click="restartFollow(scope.row)" type="text" size="small"
                              v-loading.fullscreen.lock="fullscreenLoading">
                              <svg-icon class="greenIcon" icon-class="RestartIcon"></svg-icon>
                              重启跟随
                          </el-button>
                          <el-button @click="pauseFollow(scope.row)" type="text" size="small"
                              v-loading.fullscreen.lock="fullscreenLoading">
                              <svg-icon class="greenIcon" icon-class="PauseIcon"></svg-icon>
                              暂停跟随
                          </el-button> -->
              <el-button v-if="scope.row.status == 4" @click="activateFollow(scope.row)" type="text" size="small"
                v-loading.fullscreen.lock="fullscreenLoading">
                <svg-icon class="greenIcon" icon-class="ActivateIcon"></svg-icon>
                {{ $t("MyFollowers_Activation") }}
              </el-button>
              <!-- <el-button v-if="scope.row.status == 1 || scope.row.status == 4" @click="relieveFollow(scope.row)" type="text" size="small"
                v-loading.fullscreen.lock="fullscreenLoading">
                <svg-icon class="greenIcon" icon-class="RelieveIcon"></svg-icon>
                {{ $t("MyFollowers_Unbinding") }}
              </el-button> -->
              <el-button v-if="scope.row.status !== 1 && scope.row.status !== 4 && scope.row.status !== 3" @click="retractFollow(scope.row)"
                type="text" size="small" v-loading.fullscreen.lock="fullscreenLoading">
                <svg-icon class="greenIcon" icon-class="RetractIcon"></svg-icon>
                {{ $t("MyFollowers_deleteInvite") }}
              </el-button>
              <el-button v-if="scope.row.status == 0 || scope.row.status == 2" @click="editFollow(scope.row)" type="text"
                size="small" v-loading.fullscreen.lock="fullscreenLoading">
                <svg-icon class="greenIcon" icon-class="SettingIcon"></svg-icon>
                {{ $t("MyFollowers_Setting") }}
              </el-button>
              <el-button  @click="settleUnbind(scope.row)" v-if="scope.row.status == 1" type="text"
                size="small" v-loading.fullscreen.lock="fullscreenLoading">
                <svg-icon class="greenIcon" icon-class="RelieveIcon"></svg-icon>
                {{ $t("settlementAndUnbundling") }}
              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column v-if="mobileFlag" :fixed="tableData.length !== 0?'right':false"  width="80" :label="$t('MyFollowers_Operation')">
          <template slot-scope="scope">
            <!-- {{ scope.row.insTime | dateFilter }} -->
            <div class="operateBtns">
              <el-popover placement="bottom-end" trigger="click">
                <!-- <el-button @click="restartFollow(scope.row)" type="text" size="small"
                              v-loading.fullscreen.lock="fullscreenLoading">
                              <svg-icon class="greenIcon" icon-class="RestartIcon"></svg-icon>
                              重启跟随
                          </el-button>
                          <el-button @click="pauseFollow(scope.row)" type="text" size="small"
                              v-loading.fullscreen.lock="fullscreenLoading">
                              <svg-icon class="greenIcon" icon-class="PauseIcon"></svg-icon>
                              暂停跟随
                          </el-button> -->
                <el-button v-if="scope.row.status == 4" @click="activateFollow(scope.row)" type="text" size="small"
                  v-loading.fullscreen.lock="fullscreenLoading">
                  <svg-icon class="greenIcon" icon-class="ActivateIcon"></svg-icon>
                  {{ $t("MyFollowers_Activation") }}
                </el-button>
                <!-- <el-button v-if="scope.row.status == 1 || scope.row.status == 4" @click="relieveFollow(scope.row)" type="text" size="small"
                  v-loading.fullscreen.lock="fullscreenLoading">
                  <svg-icon class="greenIcon" icon-class="RelieveIcon"></svg-icon>
                  {{ $t("MyFollowers_Unbinding") }}
                </el-button> -->
                <el-button v-if="scope.row.status !== 1 && scope.row.status !== 4" @click="retractFollow(scope.row)"
                  type="text" size="small" v-loading.fullscreen.lock="fullscreenLoading">
                  <svg-icon class="greenIcon" icon-class="RetractIcon"></svg-icon>
                  {{ $t("MyFollowers_deleteInvite") }}
                </el-button>
                <el-button v-if="scope.row.status == 0 || scope.row.status == 2" @click="editFollow(scope.row)"
                  type="text" size="small" v-loading.fullscreen.lock="fullscreenLoading">
                  <svg-icon class="greenIcon" icon-class="SettingIcon"></svg-icon>
                  {{ $t("MyFollowers_Setting") }}
                </el-button>
                <i slot="reference" class="mobileSetting el-icon-setting"></i>
              </el-popover>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="tableTotalNum">
              {{ $t('tableTotalvolumns') + ' : ' + totalVolume + ' ' + $t('tableTotalprofit') + ' : ' + totalProfit + ' '}}
            </div>
      <el-pagination class="tablePage" :prev-text="$t('MyFollowers_PreviousPage')" :next-text="$t('MyFollowers_NexPage')"
        :current-page="currentPage" @current-change="handleCurrentChange" :page-size="pageSize"
        @size-change="handleSizeChange" :page-sizes="[5, 10, 20, 50, 100]"
        layout="prev, pager, next, sizes" :total="total">
      </el-pagination>
    </div>
    <div class="mobileTableList" v-show="mobileFlag">
      <div class="tableTotalTop">
                  <span>{{ $t('tableTotalvolumns') }}</span>
                  <b>
                    {{ totalVolume }}
                  </b>
                </div>
                <div class="tableTotalTop">
                  <span>{{ $t('tableTotalprofit') }}</span>
                  <b :class="[totalProfit > 0 ?'greenNum':'redNum']">
                    {{ totalProfit }}
                  </b> 
                </div>
            <el-collapse accordion @change="collapseChange">
              <el-collapse-item v-for="(item,index) in tableData" :key="index">
                <template slot="title">
                  <div class="mobileTableCard" @click.stop="showCollapse()">
                    <div class="mobileTableItem mobileTableStatusTitle">
                      <div >
                        <span v-if="item.status == 0" class="statusGreen">{{
                            $t("MyFollowers_InvitedPendingApproval")
                          }}</span>
                          <span v-if="item.status == 4" class="statusYellow">{{
                            $t("MyFollowers_InvitationAccepted")
                          }}</span>
                          <span v-if="item.status == 2" class="statusRed">{{
                            $t("MyFollowers_InvitationDeclined")
                          }}</span>
                          <span v-if="item.status == 1" class="statusYellow">{{
                            $t("MyFollowers_Copying")
                          }}</span>
                          <span v-if="item.status == 3" class="statusRed">{{
                            $t("MyFollowers_StopCopying1")
                          }}</span>
                      </div>
                      <!-- <el-checkbox
                              class="mobileSelectCheckbox"
                              v-if="item.status == 1"
                              :value="item.checked"
                              @change="ids(item.id)"
                              ></el-checkbox> -->
                    </div>
                    <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                        {{ $t('MyFollowers_CopyAccount') }}
                      </p>
                      <p class="mobileTableContent " >
                        {{ item.followLoginId }}
                      </p>
                    </div>
                    <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                        {{ $t('MyFollowers_MT4Account') }}
                      </p>
                      <p class="mobileTableContent mobileUnderline" v-if="item.status == 1"  @click="toEcharts(item.account)">
                            {{ item.followAccount }}
                      </p>
                      <p class="mobileTableContent" v-else >
                            {{ item.followAccount }}
                      </p>
                    </div>
                    <div class="mobileTableItem">
                      <p class="mobileTableTitle labelIconDiv" >
                        {{ $t('ToBeDivided') }}
                        <a href="javascript:;" class="labelIconDivMobile"  @click.stop="
                          labelIconClick(
                            $t('ToBeDivided'),
                            $t('ToBeDividedDesc1'),
                            $t('ToBeDividedDesc2'),
                          )
                        ">
                          <svg-icon class="labelIcon" icon-class="LabelIcon"></svg-icon>
                        </a>
                      </p>
                      <p class="mobileTableContent" >
                        {{  formatNumberFractionDigits(item.notSettlementProfit) }} 
                      </p>
                    </div>
                    <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                        {{ $t('InitialNetValue') }}
                      </p>
                      <p  class="mobileTableContent" >
                        {{  formatNumberFractionDigits(item.beginPeriodEquity)    }} 
                      </p>
                    </div>
                  </div>
                  <div class="mobileTableBtns" @click.stop="showCollapse()">
                        

                        <el-button class="mobileBlueBtn" v-if="item.status == 4" @click="activateFollow(item)" type="text" size="small"
                          v-loading.fullscreen.lock="fullscreenLoading">
                          <svg-icon class="greenIcon" icon-class="ActivateIcon"></svg-icon>
                          {{ $t("MyFollowers_Activation") }}
                        </el-button>
                        <!-- <el-button class="mobileBlueBtn" v-if="item.status == 1 || item.status == 4" @click="relieveFollow(item)" type="text" size="small"
                          v-loading.fullscreen.lock="fullscreenLoading">
                          <svg-icon class="greenIcon" icon-class="RelieveIcon"></svg-icon>
                          {{ $t("MyFollowers_Unbinding") }}
                        </el-button> -->
                        <el-button class="mobileRedBtn" v-if="item.status !== 1 && item.status !== 4" @click="retractFollow(item)"
                          type="text" size="small" v-loading.fullscreen.lock="fullscreenLoading">
                          <svg-icon class="greenIcon" icon-class="RetractIcon"></svg-icon>
                          {{ $t("MyFollowers_deleteInvite") }}
                        </el-button>
                        <el-button class="mobileBlueBtn" v-if="item.status == 0 || item.status == 2" @click="editFollow(item)" type="text"
                          size="small" v-loading.fullscreen.lock="fullscreenLoading">
                          <svg-icon class="greenIcon" icon-class="SettingIcon"></svg-icon>
                          {{ $t("MyFollowers_Setting") }}
                        </el-button>

                        <el-button class="mobileBlueBtn" v-if="item.status == 1 " @click="settleUnbind(item)" type="text"
                          size="small" v-loading.fullscreen.lock="fullscreenLoading">
                          <svg-icon class="greenIcon" icon-class="RelieveIcon"></svg-icon>
                          {{ $t("settlementAndUnbundling") }}
                        </el-button>
                  </div>
                  
                </template>
                <div class="mobileTableCard" >
                    <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                        {{ $t('CurrentDeposit') }}
                      </p>
                      <p class="mobileTableContent">
                        {{  formatNumberFractionDigits(item.currentPeriodDeposit)    }} 
                      </p>
                    </div>
                    <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                        {{ $t('NetCurrentValue') }}
                      </p>
                      <p class="mobileTableContent">
                        {{ formatNumberFractionDigits(item.equity)   }} 
                      </p>
                    </div>
                    <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                        {{ $t('NetCurrentBalance') }}
                      </p>
                      <p class="mobileTableContent">
                        {{ formatNumberFractionDigits(item.balance)    }} 
                      </p>
                    </div>
                    <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                        {{ $t('NetCurrentProfit') }}
                      </p>
                      <p :class="[item.profit > 0 ?'greenNum':(item.profit < 0?'redNum':'') , 'mobileTableContent']" >

                        {{ item.profit > 0 ? '+' +formatNumberFractionDigits(item.profit) :(item.profit < 0? formatNumberFractionDigits(item.profit):'0.00') }}

                        </p>
                    </div>
                    <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                        {{ $t('BeenDivided') }}
                      </p>
                      <p class="mobileTableContent clickClass"  @click="goHistoryProfit(item.followAccount)">
                        {{ formatNumberFractionDigits(item.totalCompleteProfit)   }} 
                      </p>
                    </div>
                    <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                        {{ $t('tradeTotalProfit') }}
                      </p>
                      <p :class="[item.totalProfit > 0 ?'greenNum':(item.totalProfit < 0?'redNum':'') , 'mobileTableContent']" >

                      {{ item.totalProfit > 0 ? '+' + formatNumberFractionDigits(item.totalProfit)  :(item.totalProfit < 0?formatNumberFractionDigits(item.totalProfit)  :'0.00') }}

                      </p>
                    </div>
                    <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                        {{ $t('MyFollowers_CopyRatio') }}
                      </p>
                      <p class="mobileTableContent">
                        {{  item.following + "%"  }} 
                      </p>
                    </div>
                    <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                        {{ $t('MyFollowers_MaxLot1') }}
                      </p>
                      <p class="mobileTableContent">
                        {{  item.maxVolume == 0? $t('nolimit'):item.maxVolume + ' lots'  }} 
                      </p>
                    </div>
                    <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                        {{ $t('CurrentTradingVolume') }}
                      </p>
                      <p class="mobileTableContent">
                        {{  item.currentPeriodVolumes + 'lots'  }} 
                      </p>
                    </div>
                    <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                        {{ $t('startTimeOfThisPeriod') }}
                      </p>
                      <p class="mobileTableContent">
                        {{  item.currentSettlementBeginTime  }} 
                      </p>
                    </div>
                    <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                        {{ $t('MyFollowers_AutomaticOrderReplenishment') }}
                      </p>
                      <p class="mobileTableContent">
                        <el-result v-if="item.autoRecon" icon="success"></el-result>
                        <el-result v-if="!item.autoRecon" icon="error"></el-result>
                      </p>
                    </div>
                    <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                        {{ $t('loginStatus') }}
                      </p>
                      <p class="mobileTableContent loginStatus" v-if="item.mtApiLoginStatus == 0">
                        
                          <el-result  icon="success" ></el-result>
                          <span>{{$t('loginStatusOnline')}}</span>
                          
                        
                      </p>
                      <p class="mobileTableContent loginStatus" v-else>
                        
                        
                          <el-result  icon="error" ></el-result>
                          <span>{{$t('loginStatusOutline')}}</span>
                          
                      </p>
                    </div>
                    <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                        {{ $t('MyFollowers_Instruments1') }}
                      </p>
                      <p class="mobileTableContent">
                        <span class="symbolsSpan" v-if="item.forexSelect">
                          {{ $t("MyFollowers_Fx") }}
                        </span>
                        <span class="symbolsSpan" v-if="item.metalsSelect">
                          {{ $t("MyFollowers_Metal") }}
                        </span>
                        <span class="symbolsSpan" v-if="item.energySelect">
                          {{ $t("MyFollowers_Energy") }}
                        </span>
                        <span class="symbolsSpan" v-if="item.indicesSelect">
                          {{ $t("MyFollowers_Indices") }}
                        </span>
                        <span class="symbolsSpan" v-if="item.cryptoSelect">
                          {{ $t("MyFollowers_Crypto") }}
                        </span>
                      </p>
                    </div>
                    
                    
                </div>
              </el-collapse-item>
              
            </el-collapse>
            <div class="x" v-if="up && tableData.length < total">
                      <i  class="el-icon-loading "></i>
                      {{ $t('loading') }}
                    </div>
                    <div class="x" v-if="!up && tableData.length >= total">
                      
                      {{ $t('noMoreData') }}
                    </div>
          </div>
        </div>
      </div>
    <el-drawer :title="followAccountDrawerTitle" class="drawerCommon" :append-to-body="true" @close="cancelDrawer()"
      :close-on-press-escape="false" :visible.sync="followAccountDrawer" direction="rtl">
      <div class="drawerContent">
        <el-form :model="followForm" :rules="rules" ref="followForm" label-position="right" label-width="150px"
          class="drawerForm" :label-position="mobileFlag ? 'top' : 'left'">
          <el-form-item :label="$t('MyFollowers_CopyAccount1')" prop="loginId">
            <el-input :disabled="isEdit" v-model="followForm.loginId" maxlength="50"></el-input>
          </el-form-item>
          <el-form-item prop="following">
            <span slot="label">
              <span class="label-box">
                <span>{{ $t("MyFollowers_CopyRatio") }} </span>
                <a href="javascript:;" @click.stop="
                  labelIconClick(
                    $t('MyFollowers_CopyRatio'),
                    $t('MyFollowers_copyPercentTxt'),
                    $t('MyFollowers_copyPercentTxt1'),
                  )
                ">
                  <svg-icon class="labelIcon" icon-class="LabelIcon"></svg-icon>
                </a>
                <div class="labelIconTxt">
                  <i class="el-icon-caret-top labelIconBack"></i>
                  <div>
                    <div>
                      {{ $t('MyFollowers_copyPercentTxt') }}
                    </div><br />
                    <div>
                      {{ $t('MyFollowers_copyPercentTxt1') }}
                    </div>
                  </div>
                  
                </div>
              </span>
            </span>
            <el-input :disabled="isStatusDisabled" v-model.number="followForm.following" autocomplete="off"></el-input>
            <b class="formPercent">%</b>
          </el-form-item>
          <!-- <el-form-item prop="maxDrawdown">
            <span slot="label">
              <span class="label-box">
                <span>{{ $t("MyFollowers_MaxDrawdown") }} </span>
                <a href="javascript:;" @click.stop="
                  labelIconClick(
                    $t('MyFollowers_MaxDrawdown'),
                    $t('MyFollowers_maxDrawdownTxt')
                  )
                ">
                  <svg-icon class="labelIcon" icon-class="LabelIcon"></svg-icon>
                </a>
                <div class="labelIconTxt">
                  <i class="el-icon-caret-top labelIconBack"></i>
                  {{ $t("MyFollowers_maxDrawdownTxt") }}
                </div>
              </span>
            </span>
            <el-input-number class="creaseInput" controls-position="right" :min="0"
              v-model.number="followForm.maxDrawdown" autocomplete="off"></el-input-number>
          </el-form-item> -->
          <el-form-item prop="maxVolume">
            <span slot="label">
              <span class="label-box">
                <span>{{ $t("MyFollowers_MaxLot") }} </span>
                <a href="javascript:;" @click.stop="
                  labelIconClick(
                    $t('MyFollowers_MaxLot'),
                    $t('MyFollowers_maxVolumnTxt')
                  )
                ">
                  <svg-icon class="labelIcon" icon-class="LabelIcon"></svg-icon>
                </a>
                <div class="labelIconTxt">
                  <i class="el-icon-caret-top labelIconBack"></i>
                  <div>
                    <div>
                      {{ $t('MyFollowers_maxVolumnTxt') }}
                    </div><br />
                    <div>
                      {{ $t('MyFollowers_maxVolumnTxt1') }}
                    </div>
                  </div>
                </div>
              </span>
            </span>
            <el-input-number :disabled="isStatusDisabled" :precision="2" class="creaseInput" controls-position="right" :min="0"
              v-model.number="followForm.maxVolume" autocomplete="off" :placeholder="$t('nolimit')"></el-input-number>
              <b class="formLots">lots</b>
              <!-- <div class="formBottomTips" v-if="followForm.maxVolume == 0">
                {{
                  $t("MyFollowers_volNotLimit")
                }}
              </div> -->
          </el-form-item>
          <el-form-item :label="$t('MyFollowers_Instruments')" prop="symbolsCheck">
            <el-checkbox v-model="followForm.forexSelect" :disabled="isStatusDisabled">{{
              $t("MyFollowers_Fx")
            }}</el-checkbox>
            <el-checkbox v-model="followForm.metalsSelect" :disabled="isStatusDisabled">{{
              $t("MyFollowers_Metal")
            }}</el-checkbox>
            <el-checkbox v-model="followForm.energySelect" :disabled="isStatusDisabled">{{
              $t("MyFollowers_Energy")
            }}</el-checkbox>
            <el-checkbox v-model="followForm.indicesSelect" :disabled="isStatusDisabled">{{
              $t("MyFollowers_Indices")
            }}</el-checkbox>
            <el-checkbox v-model="followForm.cryptoSelect" :disabled="isStatusDisabled">{{
              $t("MyFollowers_Crypto")
            }}</el-checkbox>
          </el-form-item>
          <el-form-item prop="autoRecon">
            <span slot="label">
              <span class="label-box">
                <span>{{ $t("MyFollowers_WhetherToAutomaticallyReplenishOrders") }}
                </span>
                <a href="javascript:;" @click.stop="
                  labelIconClick(
                    $t('MyFollowers_WhetherToAutomaticallyReplenishOrders'),
                    $t('MyFollowers_isAutoTxt')
                  )
                ">
                  <svg-icon class="labelIcon" icon-class="LabelIcon"></svg-icon>
                </a>
                <div class="labelIconTxt">
                  <i class="el-icon-caret-top labelIconBack"></i>{{ $t("MyFollowers_isAutoTxt") }}
                </div>
              </span>
            </span> 
            <el-radio-group v-model="followForm.autoRecon" :disabled="isStatusDisabled">
              <el-radio :label="true">{{ $t("MyFollowers_Yes") }}</el-radio>
              <el-radio :label="false">{{ $t("MyFollowers_No") }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item prop="agreeActivation" :label="$t('MyFollowers_ActivateAfterClientConsent')">
            <span slot="label">
              <span class="label-box">
                <span>{{ $t("MyFollowers_ActivateAfterClientConsent") }} </span>
                <a href="javascript:;" @click.stop="
                  labelIconClick(
                    $t('MyFollowers_ActivateAfterClientConsent'),
                    $t('MyFollowers_mainActiveTxt')
                  )
                ">
                  <svg-icon class="labelIcon" icon-class="LabelIcon"></svg-icon>
                </a>
                <div class="labelIconTxt">
                  <i class="el-icon-caret-top labelIconBack"></i>{{ $t("MyFollowers_mainActiveTxt") }}
                </div>
              </span>
            </span>
            <el-radio-group v-model="followForm.agreeActivation" :disabled="isStatusDisabled">
              <el-radio :label="true">{{ $t("MyFollowers_Yes") }}</el-radio>
              <el-radio :label="false">{{ $t("MyFollowers_No") }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="$t('MyFollowers_ReasonofRejection')" v-if="checkRow.status == 2">
            <el-input resize="none"  disabled type="textarea" v-model="followForm.refuseReason"></el-input>
          </el-form-item>
        </el-form>
        <div class="drawerBtns">
          <el-button class="confirmDialogBtn" v-loading.fullscreen.lock="fullscreenLoading" @click="followSubmit()">{{
            checkRow.status == undefined
            ? $t("MyFollowers_Invite")
            : checkRow.status == 2
              ? $t("MyFollowers_InviteAgain")
              : $t("MyFollowers_Save")
          }}</el-button>
          <el-button class="cancelDialogBtn" @click="cancelDrawer()">{{
            $t("MyFollowers_CloseWindow")
          }}</el-button>
        </div>
      </div>
    </el-drawer>
    <!-- <el-drawer title="$t('MyFollowers_Activation1')撤回邀请" class="drawerCommon" :append-to-body="true" :close-on-press-escape="false"
          :visible.sync="retractInviteDrawer" direction="rtl">
          <div class="drawerContent">
              <el-input class="reasonDrawer" type="textarea" :rows="6" placeholder="$t('MyFollowers_Activation1')请输入撤回理由" v-model="reason">
              </el-input>
              <div class="drawerBtns">
                  <el-button class="confirmDialogBtn" v-loading.fullscreen.lock="fullscreenLoading"
                      @click="retractSubmit()">{{$t('MyFollowers_Activation1')}}提交</el-button>
                  <el-button class="cancelDialogBtn" @click="retractInviteDrawer = false">{{$t('MyFollowers_Activation1')}}撤销</el-button>

              </div>

          </div>

      </el-drawer> -->
    <div class="mobileLabelTips" ref="mobileLabelTips" v-if="isLableTips">
      <svg-icon class="labelIcon" icon-class="LabelIcon"></svg-icon>
      <p>
        {{ labelTitle }}
      </p>
      <div >
        <div>
          {{ labelTxt }}
        </div>
        <br />
        <div v-if="labelSubContent">
          {{ labelSubContent }}
        </div>
      </div>
    </div>
    <el-drawer
              class="mobileSearchDrawer"
              :visible.sync="mobileSearchDrawer"
              direction="ttb">
              <div class="mobileFilterReturnBtnDiv">
                <el-button  class="mobileFilterReturnBtn" @click="mobileSearchDrawer = false">
                    {{ $t('MyFollowers_Search') }}
                    <i class="el-icon-arrow-up"></i>
                </el-button>
              </div>
              
              <el-form label-position="top" :model="tableSearchForm" ref="tableSearchForm">
                  <el-form-item :label="$t('MyFollowers_Mt4AccountSearch')" prop="Account">
                    <el-input v-model="tableSearchForm.Account"></el-input>
                  </el-form-item>
                  <el-form-item :label="$t('MyFollowers_CustomerAccountSearch')" prop="LoginId">
                    <el-input v-model="tableSearchForm.LoginId"></el-input>
                  </el-form-item>
                  <el-form-item :label="$t('MyFollowers_WhetherToAutomaticallyReplenishOrders')" prop="AutoRecon">
                    <el-select v-model="tableSearchForm.AutoRecon">
                      <el-option :label="$t('MyFollowers_Yes')" value="true"></el-option>
                      <el-option :label="$t('MyFollowers_No')" value="false"></el-option>
                    </el-select>
                  </el-form-item>
                  <!-- <el-form-item :label="$t('MyFollowers_Activation1')" prop="Activation">
                              <el-select v-model="tableSearchForm.Activation">
                                  <el-option :label="$t('MyFollowers_Yes')" value="true"></el-option>
                                  <el-option :label="$t('MyFollowers_No')" value="false"></el-option>
                              </el-select>
                          </el-form-item> -->
                  <el-form-item :label="$t('MyFollowers_Status')" prop="FollowStatus">
                    <el-select v-model="tableSearchForm.FollowStatus">
                      <el-option :label="$t('MyFollowers_AllStatus')" value=""></el-option>
                      <el-option :label="$t('MyFollowers_InvitedPendingApproval')" value="0"></el-option>
                      <el-option :label="$t('MyFollowers_InvitationAccepted')" value="4"></el-option>
                      <el-option :label="$t('MyFollowers_InvitationDeclined')" value="2"></el-option>
                      <el-option :label="$t('MyFollowers_Copying')" value="1"></el-option>
                      <!-- <el-option :label="$t('MyFollowers_StopCopying1')" value="3"></el-option> -->
                      <!-- <el-option label="停止跟单" value="3"></el-option> -->
                    </el-select>
                  </el-form-item>
                  <!-- <el-form-item v-if="mobileFlag"></el-form-item> -->
                </el-form>
                <div class="mobileFilterBottomBtns">
                  <el-button class="resetFormBtn" @click="resetForm()">
                      {{ $t('MyFollowers_Reset') }}
                  </el-button>
                  <el-button class="serchFormBtn" @click="getData(1, pageSize,'search')">
                      {{ $t('MyFollowers_Search') }}
                  </el-button>
                </div>
            </el-drawer>
    <PopupTips :popupVisible='isPopupVisible' :icon="iconImg" :title="$t('MyFollowers_prompt')"
      @confirmPopupTips="confirmPopup"
      @cancelPopupTips="cancelPopup"
      ref="popupTipsRef"
      :isHtml="true"
      :confirmTxt="confirmTxt"
      :cancelTxt="cancelTxt"
      :subContent="$t(subContent)"
      :content="$t(popupContent)" :btnType="2" />

      <el-dialog :title="$t('settlementAndUnbundling')" :visible.sync="dialogVisible"  :close-on-click-modal="false"
        width="40%" top="5vh">
        <el-form :model="settleUnbindForm" :rules="rules" ref="settleUnbindForm" :label-position="mobileFlag ? 'top' : 'left'" @submit.native.prevent label-width="150px">
          <el-form-item :label="$t('startTimeOfThisPeriod')"  prop="currentSettlementBeginTime">
            <el-input disabled v-model="settleUnbindForm.currentSettlementBeginTime" maxlength="40" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item :label="$t('InitialNetValue')" prop="beginPeriodEquity" >
            <el-input disabled v-model="settleUnbindForm.beginPeriodEquity" maxlength="40" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item :label="$t('CurrentDeposit')" prop="currentPeriodDeposit">
            <el-input disabled v-model="settleUnbindForm.currentPeriodDeposit" maxlength="40" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item :label="$t('NetSettlementValue')" prop="endPeriodEquity">
            <el-input  disabled v-model="settleUnbindForm.endPeriodEquity" autocomplete="off" ></el-input>
          </el-form-item>
          <el-form-item :label="$t('profitSharingRatio')" prop="shareProfitPercent">
            <el-input disabled v-model="settleUnbindForm.shareProfitPercent" placeholder="0-100"></el-input>
            <b class="formPercent">%</b>
          </el-form-item>
          <el-form-item :label="$t('shareMoneyNum')"  prop="bouns">
            <el-input v-model="settleUnbindForm.bouns" disabled autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <p class="settleUnbindTips" v-if="settleUnbindForm.settlementStatus == 1">
          {{$t('settleUnbindTips') }}
        </p>
        <div slot="footer" class="dialog-footer" >
          <el-button class="confirmDialogBtn" v-if="settleUnbindForm.settlementStatus !== 1" v-loading.fullscreen.lock="fullscreenLoading" @click="settleUnbindSubmit()">{{
            $t('MyFollowers_confirm')
          }}</el-button>
        </div>
      </el-dialog>
      
  </div>
</template>

<script>
// @ is an alias to /src
import { concatParams, concatTableData } from "../../plugins/common.js";
import { mapState } from "vuex";
import PopupTips from '@/components/moudule/PopupTips.vue'
import BScroll from '@better-scroll/core'
  import Pullup from '@better-scroll/pull-up'
  import { formatNumberFractionDigits } from "@/shared/utils/formatNumber.js";
  
  BScroll.use(Pullup)

export default {
  name: "FollowAccount",
  components: {
    PopupTips
  },
  computed: {
    ...mapState(["checkPortfolio", "userInfo", "mobileFlag", "token"]),
  },
  data() {
    var checkMaxVolumn = (rule, value, callback) => {
      if (!value) {
        return callback(
          new Error(this.$t("MyFollowers_checkMaxVolumeNotNull"))
        );
      } else {
        if ((value = 0)) {
          callback(new Error(this.$t("MyFollowers_checkMaxVolumeNot0")));
        } else {
          callback();
        }
      }
    };
    var checkSymbol = (rule, value, callback) => {
      if (
        !this.followForm.forexSelect &&
        !this.followForm.energySelect &&
        !this.followForm.indicesSelect &&
        !this.followForm.metalsSelect &&
        !this.followForm.cryptoSelect
      ) {
        callback(new Error(this.$t("MyFollowers_checkSymbolNot")));
      } else {
        callback();
      }
    };
    var checkPercent = (rule, value, callback) => {
      if (!value) {
        return callback(
          new Error(this.$t("MyFollowers_checkCopyPercentNotNull"))
        );
      } else {
        // var reg = /^(100|([1-9][0-9]?)|(0|[1-9][0-9]?)(\.[\d]{1,2}))$/;
        var reg = /^([1-9]\d|[1-9]\d{2,4}|100000)$/
        if (!reg.test(value)) {
          callback(new Error(this.$t("MyFollowers_checkCopyPercentNotF")));
        } else {
          callback();
        }
      }
    };
    return {
      tableData: [],
      currentPage: 1,
      total: 0,
      pageSize: 5,
      tableSearchForm: {
        Account: "",
        AutoRecon: "",
        Activation: "",
        FollowStatus: "",
        LoginId: ''
      },
      followAccountDrawer: false,
      fullscreenLoading: false,
      followForm: {
        loginId: "",
        following: 100,
        maxDrawdown: 0,
        maxVolume: undefined,
        symbols: [],
        autoRecon: true,
        agreeActivation: true,
        energySelect: true,
        forexSelect: true,
        indicesSelect: true,
        metalsSelect: true,
        cryptoSelect: true,
        symbolsCheck: [],
      },
      rules: {
        loginId: [
          {
            required: true,
            message: this.$t("MyFollowers_checkCustomAccountNotNull"),
            trigger: "blur",
          },
        ],
        // maxVolume: [{ validator: checkMaxVolumn, trigger: "blur" }],
        symbolsCheck: [{ validator: checkSymbol, trigger: "change" }],
        following: [{ validator: checkPercent, trigger: "blur" }],
      },
      checkRow: {},
      reason: "",
      followAccountDrawerTitle: "",
      labelTitle: '',
      labelTxt: '',
      isLableTips: false,
      idList: [],
      isPopupVisible: false,
      iconImg:require('../../assets/images/NotifyWarning.svg'),
      popupContent:'',
      popupContentSub:'',
      clickType:'',
      totalProfit:0,
      totalVolume:0,
      cancelTxt:'MyFollowers_Cancel',
      confirmTxt:'MyFollowers_confirm',
      subContent:'',
      labelSubContent:'',
      mobileSearchDrawer:false,
      selectionList:[],
      up:false,
      firstOpen:false,
      isEdit:false,
      isStatusDisabled:false,
      dialogVisible:false,
      settleUnbindForm:{}
    };
  },
  created() {
    this.getData(1, this.pageSize);
    this.screenClick()

  },
  methods: {
    formatNumberFractionDigits,
    collapseChange(){
      this.$nextTick(() => {
            if(!this.firstOpen){
              this.scroll.maxScrollY = this.scroll.maxScrollY - 300
              this.firstOpen = true
            }else{
              this.scroll.maxScrollY = this.scroll.maxScrollY + 300
              this.firstOpen = false
            }
        });
    },
    scrollFn(){
        this.$nextTick(() => {
         
            if (!this.scroll) {
                this.scroll = new BScroll(this.$refs.bscroll, {
                    click: true,
                    scrollY: true,
                    probeType: 3
                });
            }else{
                this.scroll.refresh();
            }
            
            //touchEnd（手指离开以后触发） 通过这个方法来监听下拉刷新
            this.scroll.on('touchEnd', (pos) => {
                //上拉加载 总高度>下拉的高度+10 触发加载更多
                if(this.scroll.maxScrollY>pos.y + 50){
                    // console.log("加载更多")
                    this.up=true;
                    setTimeout(()=>{
                    //使用refresh 方法 来更新scroll  解决无法滚动的问题
                        if(this.tableData.length < this.total){
                          this.currentPage = this.currentPage + 1
                          this.getData(this.currentPage,this.pageSize,'noRefresh')
                          this.scroll.refresh();
                          this.up=false;
                        }else{
                          this.up=false;
                        }
                        
                   },1000) 
                   
                }
                // console.log(this.scroll.maxScrollY+"总距离----下拉的距离"+pos.y)
            })
          
            // console.log(this.scroll.maxScrollY)
        });
    },
    // 定义signal链接

    getData(pageIndex, pageSize,tag) {
      if(tag == 'search'){
        this.currentPage = 1
      }
      // debugger
      if(!this.mobileFlag || tag !== 'noRefresh'){
        this.tableData = [];

      }
      var params = {
        portfolioId: this.checkPortfolio.id ,
        account: this.tableSearchForm.Account.trim(),
        autoRecon: this.tableSearchForm.AutoRecon,
        status: this.tableSearchForm.FollowStatus,
        loginId:this.tableSearchForm.LoginId.trim(),
        pageIndex: pageIndex,
        pageSize: pageSize,
      };
      this.$axios
        .post("/Api/Portfolio/GetFollowListPage" , params)
        .then((res) => {
          let dt = res.data;
          if (dt.isSuccess) {
            // dt.result.pageList.forEach((item,index) =>{
            //     item.followTime = dateGmt8(item.followTime)
            // })
            this.total = dt.result.total;
            this.totalProfit = dt.result.totalProfit;
            this.totalVolume = dt.result.totalVolume;
            if(this.mobileFlag && tag == 'noRefresh'){
              dt.result.pageList.forEach((item) =>{
                this.tableData.push(item)
              })
              
              
            }else{
              this.tableData = concatTableData(
                dt.result.pageList,
                dt.result.total,
                this.currentPage,
                this.pageSize
              );
            }
            
            this.fullscreenLoading = false;
            if(this.mobileFlag){
              this.scrollFn()
            }
          // this.$refs.dataTable.doLayout();

            // console.log(this.tableData)
          }
        }).finally(()=>{
                this.mobileSearchDrawer = false
            });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getData(this.currentPage, this.pageSize);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getData(this.currentPage, this.pageSize);
    },
    resetForm() {
      this.$refs.tableSearchForm.resetFields();
      this.currentPage = 1
      this.getData(this.currentPage, this.pageSize);
    },
    addFollow() {
      this.followAccountDrawerTitle = this.$t(
        "MyFollowers_AddInvitationSettings"
      );
      this.checkRow = {};
      this.$nextTick(() => {
        this.$refs.followForm.clearValidate();
      });
      this.followForm.forexSelect = true;
      this.followForm.energySelect = true;
      this.followForm.indicesSelect = true;
      this.followForm.metalsSelect = true;
      this.followForm.cryptoSelect = true;
      this.followAccountDrawer = true;
      this.isEdit = false
      this.isStatusDisabled = false
    },
    cancelDrawer() {
      this.$refs.followForm.resetFields();
      this.followAccountDrawer = false;
      // this.followForm.forexSelect = false;
      // this.followForm.energySelect = false;
      // this.followForm.indicesSelect = false;
      // this.followForm.metalsSelect = false;
      // this.followForm.cryptoSelect = false;
      // console.log(this.followForm)
    },
    followSubmit() {
      this.$refs.followForm.validate((valid) => {
        if (valid) {
          this.fullscreenLoading = true;
          var params = {
            portfolioId: this.checkPortfolio.id,
            loginId: this.followForm.loginId.trim(),
            following: this.followForm.following,
            // maxDrawdown: this.followForm.maxDrawdown,
            maxVolume: this.followForm.maxVolume== undefined?0:this.followForm.maxVolume,
            autoRecon: this.followForm.autoRecon,
            agreeActivation: this.followForm.agreeActivation,
            energySelect: this.followForm.energySelect,
            forexSelect: this.followForm.forexSelect,
            indicesSelect: this.followForm.indicesSelect,
            metalsSelect: this.followForm.metalsSelect,
            cryptoSelect: this.followForm.cryptoSelect,
            id: this.checkRow.id,
          };
          // if (this.checkRow.id) {
          //     params.id = this.checkRow.id
          // }
          this.$axios
            .post("/Api/SubAccount/AddInvite", params)
            .then((res) => {
              let dt = res.data;
              if (dt.isSuccess) {
                
                this.$notify.success({title: this.$t('nofity_success'), message:this.$t('MyFollowers_operateSuccess')});
                this.getData(this.currentPage, this.pageSize);
                this.$refs.followForm.resetFields();
                this.followAccountDrawer = false;
              } else {
                this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});

              }
            })
            .finally(() => {
              this.fullscreenLoading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      // console.log(this.followForm)
    },
    // 设置
    editFollow(row) {
      this.isEdit = true
      if(row.status == 0){
        this.isStatusDisabled = false
      }else{
        this.isStatusDisabled = true
      }
      this.followAccountDrawerTitle = this.$t("MyFollowers_Setting");
      this.checkRow = row;
      this.$axios.get("/Api/SubAccount/InviteSet?id=" + row.id).then((res) => {
        let dt = res.data;
        if (dt.isSuccess) {
          this.followAccountDrawer = true;
          this.$nextTick(function () {
            this.followForm = Object.assign(this.followForm, dt.result);
            this.followForm.maxVolume = this.followForm.maxVolume == 0?undefined:this.followForm.maxVolume
          });
          
          // this.settleUnbindForm.portfolioName = dt.result.portfolioName
          // this.tableData = concatTableData(dt.result,10)
        } else {
          this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
        }
      });
      // this.addSignal(row.id)
    },
    // 重启跟随  未使用
    restartFollow(row) {
      this.fullscreenLoading = true;
      var params = {
        portfolioId: this.checkPortfolio.id,
        account: row.account,
      };
      this.$axios
        .get("/Api/SubAccount/ActiveFollow" + concatParams(params))
        .then((res) => {
          let dt = res.data;
          if (dt.isSuccess) {
            this.$notify.success({title: this.$t('nofity_success'), message:this.$t('MyFollowers_operateSuccess')});
            this.getData(this.currentPage, this.pageSize);
          } else {
            this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
          }
        })
        .finally(() => {
          this.fullscreenLoading = false;
        });
    },
    // 暂停跟随
    pauseFollow() { },
    // 解绑
    relieveFollow(row) {
      this.checkRow = row
      this.popupContent = 'MyFollowers_stopUnbingConfirm'
      this.subContent = 'MyFollowers_stopCopingContent1'
      this.clickType = 'unbind'
      this.isPopupVisible = true
      // this.$confirm(
      //   this.$t("MyFollowers_stopUnbingConfirm"),
      //   this.$t("MyFollowers_prompt"),
      //   {
      //     confirmButtonText: this.$t("MyFollowers_confirm"),
      //     cancelButtonText: this.$t("MyFollowers_Cancel"),
      //     type: "warning",
      //   }
      // ).then(() => {
      //   this.fullscreenLoading = true;
      //   var params = {
      //     portfolioId: this.checkPortfolio.id,
      //     id: row.id,
      //   };
      //   this.$axios
      //     .get("/Api/SubAccount/UnBind" + concatParams(params))
      //     .then((res) => {
      //       let dt = res.data;
      //       if (dt.isSuccess) {
      //                       this.$notify.success({title: this.$t('nofity_success'), message:this.$t('MyFollowers_operateSuccess')});

      //         this.getData(this.currentPage, this.pageSize);
      //       } else {
      //         this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
      //       }
      //     })
      //     .finally(() => {
      //       this.fullscreenLoading = false;
      //     });
      // });
    },
    unbindOne(){

      const loading = this.$loading({
          lock: true
        });
        var params = {
          portfolioId: this.checkPortfolio.id,
          id: this.checkRow.id,
        };
        this.$axios
          .get("/Api/SubAccount/UnBind" + concatParams(params))
          .then((res) => {
            let dt = res.data;
            if (dt.isSuccess) {
              this.$notify.success({title: this.$t('nofity_success'), message:this.$t('MyFollowers_operateSuccess')});
              this.getData(this.currentPage, this.pageSize);
            } else {
              this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
            }
          }).finally(()=>{
          loading.close()
        });
    },
    cancelPopup(val){
      this.isPopupVisible = val
      this.subContent = ''
    },
    confirmPopup(val){
      this.isPopupVisible = val
      this.subContent = ''
      if(this.clickType == 'unbind'){
        this.unbindOne()
      }else if(this.clickType == 'unbindAll'){
        this.unbindAll()
      }else if(this.clickType == 'delete'){
        this.deleteInvite()
      }
    },
    // 激活
    activateFollow(row) {
      this.fullscreenLoading = true;
      var params = {
        id: row.id,
      };
      this.$axios
        .get("/Api/SubAccount/ActiveFollow" + concatParams(params))
        .then((res) => {
          let dt = res.data;
          if (dt.isSuccess) {
            this.$notify.success({title: this.$t('nofity_success'), message:this.$t('MyFollowers_operateSuccess')});

            this.getData(this.currentPage, this.pageSize);
          } else {
            this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
          }
        })
        .finally(() => {
          this.fullscreenLoading = false;
        });
    },
    // 删除邀请
    retractFollow(row) {
      // this.$confirm(
      //   this.$t("MyFollowers_stopDeleteConfirm"),
      //   this.$t("MyFollowers_prompt"),
      //   {
      //     confirmButtonText: this.$t("MyFollowers_confirm"),
      //     cancelButtonText: this.$t("MyFollowers_Cancel"),
      //     type: "warning",
      //   }
      // ).then(() => {
        this.clickType = 'delete'
        this.popupContent = 'MyFollowers_stopDeleteConfirm'
        this.checkRow = row
        this.isPopupVisible = true
        
      // });
    },
    deleteInvite(){
      this.fullscreenLoading = true;
        var params = {
          id: this.checkRow.id,
        };
        this.$axios
          .get("/Api/SubAccount/RecallInvite" + concatParams(params))
          .then((res) => {
            let dt = res.data;
            if (dt.isSuccess) {
              this.$notify.success({title: this.$t('nofity_success'), message:this.$t('MyFollowers_operateSuccess')});

              const totalPage = Math.ceil((this.total - 1) / this.pageSize); // 总页数
              this.currentPage =
                this.currentPage > totalPage ? totalPage : this.currentPage;
              this.currentPage = this.currentPage < 1 ? 1 : this.currentPage;

              this.getData(this.currentPage, this.pageSize);
            } else {
              this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
            }
          })
          .finally(() => {
            this.fullscreenLoading = false;
          });
    },
    labelIconClick(title, content, subContent) {
    
      if (this.mobileFlag) {
        this.isLableTips = !this.isLableTips;
        this.labelTitle = title;
        this.labelTxt = content;
        this.labelSubContent = subContent
      } else {
        this.isLableTips = false;
      }
    },
    screenClick() {
      // debugger
      document.addEventListener("click", (e) => {
        if (this.$refs.mobileLabelTips) {
          let self = this.$refs.mobileLabelTips.contains(e.target);
          if (!self) {
            this.isLableTips = false;
            this.labelSubContent = ''

          }
        }

      });
    },
    // 多选选中
    handleSelectionChange(val) {

      this.idList = []
      val.forEach(element => {
        this.idList.push(element.id)
      });
      // if (val.length == this.detailList.length) {
      //     this.checkAll = true
      // } else {
      //     this.checkAll = false
      // }

      // console.log(this.checkAll)
      // this.multipleSelection = val;
    },
    relieveMore() {
      if(this.mobileFlag){
        if ( this.selectionList.length == 0) {
          this.$notify.warning({title: this.$t('MyFollowers_prompt'), message:this.$t('relieveAllNotNull')});

      
        } else {
          // console.log(this.idList)
          this.clickType = 'unbindAll'
          this.popupContent = 'MyFollowers_stopUnbingAllContent'
          this.subContent = 'MyFollowers_stopCopingContent1'
          this.isPopupVisible = true
        

        }
      }else{
        if (this.idList.length == 0 ) {
          this.$notify.warning({title: this.$t('MyFollowers_prompt'), message:this.$t('relieveAllNotNull')});

      
        } else {
          // console.log(this.idList)
          this.clickType = 'unbindAll'
          this.popupContent = 'MyFollowers_stopUnbingAllContent'
          this.subContent = 'MyFollowers_stopCopingContent1'
          this.isPopupVisible = true
        

        }
      }
     
    },
    unbindAll(){
      this.fullscreenLoading = true
        this.$axios
          .post("/Api/SubAccount/UnBindAll", { ids: this.mobileFlag?this.selectionList:this.idList })
          .then((res) => {
            let dt = res.data;
            if (dt.isSuccess) {
              this.$notify.success({title: this.$t('nofity_success'), message:this.$t('MyFollowers_operateSuccess')});

              this.getData(this.currentPage, this.pageSize);
            } else {
              this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
            }
          })
          .finally(() => {
            this.fullscreenLoading = false;
          });
    },
    selectable(row,index){
      // console.log(row)
      if(row.status == 1 || row.status == 4){
        return true
      }
    },
    toEcharts(account){
        this.$router.push({ path: '/FollowEcharts',query:{account:account} });
      },
      getSummaries(param) {
          const { columns, data } = param;
          const sums = [];
          columns.forEach((column, index) => {
              if (index === 0) {
                  sums[index] = this.$t('echarts_total');
                  return;
              }
              const values = data.map(item => Number(item[column.property]));
              if (column.property === 'profit' || column.property === 'volumes' || column.property === 'equity' || column.property === 'balance') {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr)
                        if (!isNaN(value)) {
                            return prev + curr
                        } else {
                            return prev
                        }
                    }, 0)
                    
                    sums[index] = formatNumberFractionDigits(sums[index])  
                } else {
                    sums[index] = ' '
                }
          });

          return sums;

      },
      //获取数组中数值的下标
    indexOf(val, ids) {
      for (let i = 0; i < ids.length; i++) {
        //获取当前值的下标
        if (ids[i] === val) {
          return i;
        }
      }
      return -1;
    },
 
    //多选赋值ids
    ids(val) {
      
      //检索下标,判断当前值(或对象是否在数组中); 在则返回在的对象,不在则返回-1
      let index = this.indexOf(val, this.selectionList);
      if (this.selectionList.length > 0 && index > -1) {
        //删除数组中的某个元素(在取消勾选时,删除数组中的值)
        this.selectionList.splice(index, 1);
      } else {
        //id添加到数组中
        this.selectionList.push(val);
        //用逗号隔开
        // this.selectionList.join(",");
      }
      // 发射数据给父组件：
      console.log(this.selectionList)
    },

    goHistoryProfit(account){
      this.$emit('GoHistory','signalHistoricalProfit',account)
    },
    settleUnbind(row){
      this.checkRow = row
      this.fullscreenLoading = true
      this.$axios.get("/Api/invite/StopFollowDetail?inviteId="+row.id).then((res) => {
        let dt = res.data;
        if (dt.isSuccess) {
          this.fullscreenLoading = false
          this.dialogVisible = true
          this.settleUnbindForm = dt.result;
          this.settleUnbindForm.currentPeriodDeposit = formatNumberFractionDigits(this.settleUnbindForm.currentPeriodDeposit)
          this.settleUnbindForm.beginPeriodEquity = formatNumberFractionDigits(this.settleUnbindForm.beginPeriodEquity)
          this.settleUnbindForm.endPeriodEquity = formatNumberFractionDigits(this.settleUnbindForm.endPeriodEquity)

        }
      }).finally(() => {
        this.fullscreenLoading = false;
      });

    },
    settleUnbindSubmit(){
      this.$confirm(
        this.$t("settleUnbindConfirmTips"),
        this.$t("MyFollowers_prompt"),
        {
          confirmButtonText: this.$t("MyFollowers_confirm"),
          cancelButtonText: this.$t("MyFollowers_Cancel"),
          type: "warning",
        }
      ).then(() => {
        this.fullscreenLoading = true;
        this.$axios
          .get("/Api/Portfolio/SettltementInvitation?id="+this.checkRow.id)
          .then((res) => {
            let dt = res.data;
            if (dt.isSuccess) {
              this.$notify.success({title: this.$t('nofity_success'), message:this.$t('MyFollowers_operateSuccess')});
              this.getData(this.currentPage, this.pageSize);
              this.dialogVisible = false
            } else {
              this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
            }
          })
          .finally(() => {
            this.fullscreenLoading = false;
          });
      });
    }
  },
};
</script>
<style lang="less" scoped>
.followAccount {
  .pageTitleBtn {
    margin-bottom: 20px;
  }
  .operateBtns{
    padding-left: 0;
  }
  
}
.refreshListBtnDiv{
  position: absolute;
  right: 0;
  top: -155px;
}
.clickClass{
  color: #4d71ff!important;
  cursor: pointer;
}
.settleUnbindTips{
  margin: 30px 0;
  border-top: 1px solid #d0d0d0;
  padding-top: 20px;
  color: #e35972;
}

@media screen and (max-width: 767px) {
  .mobileFilterDiv{
    justify-content: space-between;
  }
  .followAccount {
    .pageTitleBtn {
      margin: 20px 0 0 0;
    }
    
  }
  .serchFormBtn, .resetFormBtn {
      min-width: 150px;
      
    }
    .tableSearch{
      padding-top: 40px;
    }
    .refreshListBtnDiv{
    position: absolute;
    left: 0;
    top: 30px!important;
    .confirmDialogBtn{
        padding: 10px ;
        font-size: 12px;
        line-height: 15px;
      }
  }

  .labelIconDiv{
    display:flex;
    flex-direction: row;
    .labelIconDivMobile{
      margin-left: 10px
    }
  }
}
</style>
